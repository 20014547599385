import image from './join-vip-club-footer.jpg';
import BackgroundImage from '~/components/BackgroundImage';
import {css} from '@styled-system/css';
import {Button} from '~/components/ui/Button';
import {Link} from '@remix-run/react';
import {Container, Stack} from '@styled-system/jsx';

const JoinVipFooter = () => {
  return <BackgroundImage
    minH="500px"
    backgroundPosition="center right -500px"
    backgroundRepeat="no-repeat"
    bgColor="black"
    src={image}
    color="white"
  >
    <Container width="full">
      <Stack maxWidth={{base: "50%", mdDown: "full"}}>
        <h1 className={css({
          fontSize: '6xl',
          textAlign: {
            mdDown: 'center',
          },
          fontFamily: 'heading',
          textTransform: 'uppercase',
          fontWeight: 700,
          lineHeight: 1,
        })}>
          Join our VIP Club
        </h1>
        <p>
          Unlock personalized emails with special offers, free shipping on all orders, access to members-only
          promotions, premium concierge service, and a special annual gift just for you. Don’t miss out on these
          incredible perks!
        </p>
        <div>
          <Button
            asChild
            w={{mdDown: 'full'}}
            size="lg"
          >
            <Link to="/vip-club">
              Become a Member
            </Link>
          </Button>
        </div>
      </Stack>
    </Container>
  </BackgroundImage>
}

export default JoinVipFooter;
